import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

import CellComponent from './components/CellComponent/CellComponent'
import CustomerAndSiteIdSelector from './components/CustomerAndSiteIdSelector/CustomerAndSiteIdSelector'
import { ERROR_CODE_MESSAGES } from './consts'
import InputContainer from './InputContainer'
import {
  ButtonContainerStyled,
  HeaderContainerStyled,
  StyledLabel,
} from './styles'
import Button2 from '../../../components/Button2'
import StyledCheckbox from '../../../components/Checkbox'
import StyledRadioButton from '../../../components/RadioButton'
import RedaptiveReactTable from '../../../components/RedaptiveReactTable'
import Spinner from '../../../components/Spinner'
import {
  actions as customerActions,
  selectCustomerListEntity,
} from '../../../ducks/customers'
import {
  actions as electronVerificationActions,
  API as evAPI,
} from '../../../ducks/electronVerificationV1'
import { API as MeterAPI } from '../../../ducks/meters/index'
import { API as MeterStatusAPI } from '../../../ducks/meterStatus'
import { actions as modalActions } from '../../../ducks/modal'
import {
  actions as siteActions,
  selectSiteListEntity,
} from '../../../ducks/sites'
import { push } from 'connected-react-router'

const HeadingStyled = styled.h2`
  font-family: 'Avenir Next';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #4a4a4a;
  margin-top: 40px;
  margin-bottom: 0;
`
const DividerStyled = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(108, 109, 110, 0.32);
  margin: 12px 0 24px;
`

const StyledRadioGroup = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  div {
    display: flex;
    align-items: center;
    margin-right: 24px;
    ${StyledLabel} {
      margin-left: 8px;
    }
  }
`
const StyledErrorMessage = styled.p`
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`
const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  ${StyledLabel} {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 0;
    padding-top: 0;
  }
`

type MeterFetchParams = {
  customerId?: string
  siteId?: string
  meterMAC?: string
  opportunityId?: string
  pageNumber: number
  pageSize: number
  orderBy?: { field: string; sort: string }
}

const FullEV = () => {
  const customerListEntity = useSelector(selectCustomerListEntity)
  const siteListEntity = useSelector(selectSiteListEntity)

  const { push } = useHistory()

  const dispatch = useDispatch()
  const location = useLocation()

  const isFirstRender = useRef(true)

  const searchParams = new URLSearchParams(location.search)
  const searchByFromQuery = searchParams.get('searchBy')
  const meterMacFromQuery = searchParams.get('meterMac') || null
  const opportunityIdFromQuery = searchParams.get('opportunityId') || null
  const customerIdFromQuery = searchParams.get('customerId') || null
  const siteIdFromQuery = searchParams.get('siteId') || null
  if (customerListEntity.items.length === 0 && searchParams.get('searchBy')) {
    push('/reports/webev-v1')
  }
  const [searchBy, setSearchBy] = useState(
    searchByFromQuery || 'customerAndSiteID',
  )

  const [customerId, setCustomerId] = useState<string | null>(
    customerIdFromQuery,
  )
  const [siteId, setSiteId] = useState<string | null>(siteIdFromQuery)

  const [opportunityId, setOpportunityId] = useState<string | null>(
    opportunityIdFromQuery,
  )
  const [meterMAC, setMeterMAC] = useState<string | null>(meterMacFromQuery)

  const [loading, setLoading] = useState(false)
  const [dataAvailableLoading, setDataAvailableLoading] = useState(false)
  const [meterStatusLoading, setMeterStatusLoading] = useState(false)
  const [meters, setMeters] = useState([])
  const [selectedMeters, setSelectedMeters] = useState<string[]>([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalResults, setTotalResults] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')

  const renderSearchByFields = () => {
    switch (searchBy) {
      case 'customerAndSiteID': {
        return (
          <CustomerAndSiteIdSelector
            customerId={customerId}
            setCustomerId={(value) => {
              if (searchByFromQuery) {
                return
              }
              setCustomerId(value)
              dispatch(
                siteActions.fetchAllSites({
                  customerId: value,
                  pageSize: 10 ** 4,
                  pageNumber: 1,
                }),
              )
            }}
            siteId={siteId}
            setSiteId={setSiteId}
            customerListEntity={customerListEntity}
            siteListEntity={siteListEntity}
            disabled={loading || meters.length > 0 || errorMsg.length > 0}
          />
        )
      }
      case 'byMeterMAC':
        return (
          <InputContainer
            onInputChange={setMeterMAC}
            placeholder='Enter Mac Address'
            label='Meter Mac'
            value={meterMAC}
            disabled={loading || meters.length > 0 || errorMsg.length > 0}
            data-testid='meterMacInput'
          />
        )
      case 'byOpportunityID':
        return (
          <InputContainer
            onInputChange={setOpportunityId}
            placeholder='Enter Opportunity ID'
            label='Opportunity ID'
            value={opportunityId}
            disabled={loading || meters.length > 0 || errorMsg.length > 0}
            data-testid='oppotunityIdInput'
          />
        )
      default:
        return null
    }
  }

  const fetchAndSetMeters = async (params: MeterFetchParams) => {
    setLoading(true)
    setMeters([])
    setSelectedMeters([])

    const meterResponse = await MeterAPI.fetchMeterListForEV(params)
    const metersResp = meterResponse.results

    setTotalResults(meterResponse.totalCount)

    const meterIDs = metersResp.map((meter) => meter.name)

    if (meterIDs.length === 0) {
      setLoading(false)
      setMeters([])
      setErrorMsg('No meters found for the selected parameters')
      return
    }
    setLoading(false)
    setMeters(metersResp)

    setMeterStatusLoading(true)
    const meterStatusResponse = await MeterStatusAPI.fetchRTMeterStatus({
      ids: meterIDs,
    })
    const meterStatus = meterStatusResponse.results

    setMeterStatusLoading(false)
    const metersWithStatus = metersResp.map((meter) => {
      const status = meterStatus.find(
        (status) => status.macAddress === meter.name,
      )
      return {
        ...meter,
        lastMeasurementDate: status.lastMeasurementDate,
        lastReportDate: status.lastReportDate,
        onlineStatus: status.onlineStatus,
      }
    })
    setMeters(metersWithStatus)

    setDataAvailableLoading(true)

    const meterDataAvailability = (
      await MeterAPI.fetchMeterTestDataAvailability(meterIDs)
    ).data

    const metersWithDataAvailability = metersWithStatus.map((meter) => {
      const dataAvailable = meterDataAvailability[meter.name]
      return {
        ...meter,
        dataAvailable,
      }
    })

    setDataAvailableLoading(false)

    setMeters(metersWithDataAvailability)
  }

  const fetchMeters = async () => {
    if ((customerId && siteId) || meterMAC || opportunityId) {
      await fetchAndSetMeters({
        customerId: customerId || undefined,
        siteId: siteId || undefined,
        pageNumber,
        meterMAC: meterMAC || undefined,
        opportunityId: opportunityId || undefined,
        pageSize,
        orderBy: { field: 'lastConfigDate', sort: 'DESC' },
      })
    }
  }
  const hasAllRequiredFields = () => {
    switch (searchBy) {
      case 'customerAndSiteID':
        return customerId && siteId
      case 'byMeterMAC': {
        // ValdiationPattern for MAC Address
        // 12 alphanumeric characters, case insensitive, semicolon allowed
        const macAddressPattern = /^[0-9A-Fa-f]{12}(:[0-9A-Fa-f]{2})?$/
        if (meterMAC) {
          return macAddressPattern.test(meterMAC)
        }
        return false
      }
      case 'byOpportunityID': {
        // ValidationPattern for Opportunity ID
        // 9 alphanumeric characters, should either begin with E or R
        const opportunityIDPattern = /^[ER][0-9A-Za-z]{8}$/
        if (opportunityId) {
          return opportunityIDPattern.test(opportunityId)
        }
        return false
      }
      default:
        return false
    }
  }
  const handleCheckboxClick = useCallback((originalName: string) => {
    setSelectedMeters((prevSelectedMeter) => {
      if (
        prevSelectedMeter.length === 10 &&
        !prevSelectedMeter.includes(originalName)
      ) {
        dispatch(
          modalActions.showConfirmModal({
            primaryActionText: 'CLOSE',
            onPrimaryAction: () => {
              dispatch(modalActions.hideModal())
            },
            renderBody: () => (
              <StyledModalBody>
                <StyledLabel>
                  You can only select 10 meters at a time.
                </StyledLabel>
                {/* Discussing if we need to change the style of text */}
                {/* <StyledErrorMessage>
                  You can only select 10 meters at a time.
                </StyledErrorMessage> */}
              </StyledModalBody>
            ),
            modalWidth: '570px',
          }),
        )
        return prevSelectedMeter
      }
      if (prevSelectedMeter.includes(originalName)) {
        return prevSelectedMeter.filter((name) => name !== originalName)
      }
      return [...prevSelectedMeter, originalName]
    })
  }, [])
  const RenderSelectionCell = useCallback(
    ({ original }) => (
      <td data-testid='enable'>
        <StyledCheckbox
          checked={selectedMeters.includes(original.name)}
          onClick={() => handleCheckboxClick(original.name)}
          aria-label='Select Meter'
        />
      </td>
    ),
    [selectedMeters],
  )

  const columns = [
    {
      accessor: 'created',
      Cell: RenderSelectionCell,
      Header: 'Enable',
      disableFilters: true,
      maxWidth: 100,
    },
    {
      accessor: 'name',
      Cell: CellComponent,
      Header: 'Meter MAC',
      id: 'name',
      disableFilters: true,
      maxWidth: 160,
      minWidth: 160,
    },
    {
      accessor: 'siteName',
      Cell: CellComponent,
      Header: 'Site',
      id: 'siteName',
      disableFilters: true,
      maxWidth: 250,
      minWidth: 250,
    },
    {
      accessor: 'customerName',
      Cell: CellComponent,
      Header: 'Customer',
      id: 'customerName',
      disableFilters: true,
      maxWidth: 150,
      minWidth: 150,
    },
    {
      accessor: 'onlineStatus',
      Cell: meterStatusLoading ? 'Loading...' : CellComponent,
      Header: 'Online Status',
      id: 'onlineStatus',
      disableFilters: true,
      maxWidth: 200,
    },
    {
      accessor: 'firstReportDate',
      Cell: CellComponent,
      Header: 'First Report Date',
      id: 'firstReportDate',
      disableFilters: true,
      maxWidth: 300,
      minWidth: 300,
    },
    {
      accessor: 'mostRecentConfigurationDate',
      Cell: CellComponent,
      Header: 'Last Config Date',
      id: 'mostRecentConfigurationDate',
      disableFilters: true,
      maxWidth: 300,
      minWidth: 300,
    },
    {
      accessor: 'verified',
      Cell: CellComponent,
      Header: 'Electron Verified',
      id: 'verified',
      disableFilters: true,
      maxWidth: 160,
      minWidth: 160,
      width: 160,
    },
    {
      accessor: 'dataAvailable',
      Cell: dataAvailableLoading ? 'Loading...' : CellComponent,
      Header: 'Data Available',
      id: 'dataAvailable',
      disableFilters: true,
      maxWidth: 160,
      minWidth: 160,
      width: 160,
    },
    {
      accessor: 'name',
      Cell: CellComponent,
      Header: 'History',
      id: 'history',
      disableFilters: true,
      maxWidth: 160,
      minWidth: 160,
      width: 160,
    },
  ]

  const navigateToFullEV = async () => {
    setLoading(true)
    if (!selectedMeters) {
      return
    }
    const evResponse = await evAPI.fetchMeterElectronVerification({
      macAddress: selectedMeters,
      isLabelCheckOnly: false,
    })
    setLoading(false)
    if (evResponse.length < 1) {
      return
    }
    if (evResponse[0].errorMessage && evResponse[0].errorCode) {
      const { errorCode } = evResponse[0]
      const errorDetails = ERROR_CODE_MESSAGES[errorCode]

      dispatch(
        modalActions.showConfirmModal({
          primaryActionText: 'CLOSE',
          onPrimaryAction: () => {
            dispatch(modalActions.hideModal())
          },
          renderBody: () => (
            <StyledModalBody>
              <StyledLabel>{errorDetails.title}</StyledLabel>
              <StyledErrorMessage>{errorDetails.message}</StyledErrorMessage>
            </StyledModalBody>
          ),
          modalWidth: '570px',
        }),
      )

      return
    }
    dispatch(
      electronVerificationActions.updateMeterSelectionMeta({
        searchBy,
        siteId,
        customerId,
        meterMac: meterMAC,
        opportunityId,
      }),
    )
    push(`/reports/webev-v1/${selectedMeters}`, evResponse)
  }

  const navigateToLabelChecks = async () => {
    setLoading(true)
    if (!selectedMeters) {
      return
    }
    const evResponse = await evAPI.fetchMeterElectronVerification({
      macAddress: selectedMeters,
      isLabelCheckOnly: true,
    })
    setLoading(false)
    if (evResponse.length < 1) {
      return
    }
    if (evResponse[0].errorMessage && evResponse[0].errorCode) {
      const { errorCode } = evResponse[0]
      const errorDetails = ERROR_CODE_MESSAGES[errorCode]

      dispatch(
        modalActions.showConfirmModal({
          primaryActionText: 'CLOSE',
          onPrimaryAction: () => {
            dispatch(modalActions.hideModal())
          },
          renderBody: () => (
            <StyledModalBody>
              <StyledLabel>{errorDetails.title}</StyledLabel>
              <StyledErrorMessage>{errorDetails.message}</StyledErrorMessage>
            </StyledModalBody>
          ),
          modalWidth: '570px',
        }),
      )

      return
    }
    dispatch(
      electronVerificationActions.updateMeterSelectionMeta({
        searchBy,
        siteId,
        customerId,
        meterMac: meterMAC,
        opportunityId,
      }),
    )
    push(`/reports/webev-v1/${selectedMeters}?onlyLabelCheck=true`, evResponse)
  }

  const navigateToDashboard = () => {
    push(`/reports/electron-verify-dashboard-v1`)
  }

  useEffect(() => {
    if (searchByFromQuery) {
      return
    }
    dispatch(
      customerActions.fetchAllCustomers({
        pageSize: 10 ** 4,
        orderBy: { field: 'name', sort: 'ASC' },
        pageNumber: 1,
      }),
    )
  }, [])

  useEffect(() => {
    if (isFirstRender.current && !searchByFromQuery) {
      isFirstRender.current = false
      return
    }

    fetchMeters()
  }, [pageSize, pageNumber])

  const isNoMeterSelected = selectedMeters.length === 0
  return (
    <div>
      <HeaderContainerStyled>
        <HeadingStyled>Search Meter</HeadingStyled>
        <ButtonContainerStyled>
          <Button2 type='redaptiveTransparent' onClick={navigateToDashboard}>
            Return To Dashboard
          </Button2>
          <Button2
            type='redaptiveTransparent'
            onClick={() => {
              setMeters([])
              setSelectedMeters([])
              setCustomerId(null)
              setMeterMAC(null)
              setOpportunityId(null)
              setSearchBy('customerAndSiteID')
              setSiteId(null)
              setPageNumber(1)
              setPageSize(10)
              setErrorMsg('')
              push('/reports/webev-v1')
            }}
          >
            Run New Analysis
          </Button2>
        </ButtonContainerStyled>
      </HeaderContainerStyled>
      <DividerStyled />
      <StyledRadioGroup>
        <div>
          <StyledRadioButton
            id='searchBy'
            name='searchBy'
            value='customerAndSiteID'
            checked={searchBy === 'customerAndSiteID'}
            disabled={loading || meters.length > 0 || errorMsg.length > 0}
            onClick={() => setSearchBy('customerAndSiteID')}
            data-testid='byCustomerAndSiteIDRadio'
          />
          <StyledLabel>By Customer + Site ID</StyledLabel>
        </div>
        <div>
          <StyledRadioButton
            id='searchBy'
            name='searchBy'
            value='byMeterMAC'
            checked={searchBy === 'byMeterMAC'}
            disabled={loading || meters.length > 0 || errorMsg.length > 0}
            onClick={() => setSearchBy('byMeterMAC')}
            data-testid='byMeterMACRadio'
          />
          <StyledLabel>By Meter MAC</StyledLabel>
        </div>
        <div>
          <StyledRadioButton
            id='searchBy'
            name='searchBy'
            checked={searchBy === 'byOpportunityID'}
            value='byOpportunityID'
            disabled={loading || meters.length > 0 || errorMsg.length > 0}
            onClick={() => setSearchBy('byOpportunityID')}
            data-testid='byOpportunityIDRadio'
          />
          <StyledLabel>By Opportunity ID</StyledLabel>
        </div>
      </StyledRadioGroup>
      {renderSearchByFields()}
      {meters.length === 0 && errorMsg.length === 0 && (
        <ButtonContainerStyled>
          <Button2
            type='redaptiveSecondary'
            disabled={false}
            onClick={() => {
              setMeters([])
              setSelectedMeters([])
              setCustomerId(null)
              setMeterMAC(null)
              setOpportunityId(null)
              setSearchBy('customerAndSiteID')
              setSiteId(null)
              setPageNumber(1)
              setPageSize(10)
              setErrorMsg('')
            }}
          >
            {' '}
            RESET
          </Button2>
          <Button2
            type='redaptivePrimary'
            disabled={!hasAllRequiredFields()}
            onClick={() => {
              setPageNumber(1)
              setPageSize(10)
              fetchMeters()
            }}
          >
            SUBMIT
          </Button2>
        </ButtonContainerStyled>
      )}
      {loading && <Spinner size='small' />}
      {errorMsg && <StyledLabel>{errorMsg}</StyledLabel>}
      {hasAllRequiredFields() && meters.length > 0 && !loading && (
        <>
          <HeaderContainerStyled>
            <HeadingStyled>Select Meter and Run EV</HeadingStyled>
          </HeaderContainerStyled>

          <DividerStyled />
          <RedaptiveReactTable
            className='-striped -highlight'
            data={meters}
            columns={columns}
            filterable={false}
            sortable={false}
            resizable={false}
            setPageSize={setPageSize}
            pageSize={pageSize}
            showCustomPagination
            gotoPage={(page: number) => setPageNumber(page)}
            pageIndex={pageNumber - 1}
            totalResults={totalResults}
            paginationSize={[10]}
            hideAllPagination
          />
          <ButtonContainerStyled>
            <Button2
              type='redaptivePrimary'
              onClick={navigateToFullEV}
              disabled={isNoMeterSelected}
            >
              {' '}
              RUN FULL EV
            </Button2>
            <Button2
              type='redaptiveOutlined'
              onClick={navigateToLabelChecks}
              disabled={isNoMeterSelected}
            >
              RUN LABEL CHECK
            </Button2>
          </ButtonContainerStyled>
        </>
      )}
    </div>
  )
}

export default FullEV
