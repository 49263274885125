import { ReactNode } from 'react'
import styled from 'styled-components'

type FTVariant = {
  description: ReactNode
  title: string
}
type FTVariants = Map<string, FTVariant>
const StyledDescription = styled.div`
  margin: 2px 26px;

  p {
    margin: 0;
  }
`
const StyledLink = styled.a`
  color: #337ab7;

  &:hover {
    text-decoration: underline;
  }
`
const defaultVariants: FTVariants = new Map([
  [
    'Placeholder',
    {
      title:
        'This is just a placeholder. There are currently no functional variants.',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/TPD-573'>
              TPD-573
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
])
const activeVariants: FTVariants = new Map([
  [
    '797mock',
    {
      title: 'Mock backend data for panel circuits.',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/GRN-797'>
              GRN-797
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
  [
    '3300mock',
    {
      title: 'Mock backend data for proposals.',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/TPD-3300'>
              TPD-3300
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
  [
    '3587mock',
    {
      title: 'Mocks the data for the billing workflow.',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/TPD-3587'>
              TPD-3587
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
  [
    '240mock',
    {
      title: 'Mocks the data for the Meter Install Submissions',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/TPD-3587'>
              TPD-3587
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
  [
    'GRN247mock',
    {
      title: 'Meter Install Submission Details',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/GRN-247'>
              GRN-247
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
  [
    'ORN38mock',
    {
      title: 'Energy star submissions page',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/ORN-38'>
              ORN-38
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
  [
    'TEAL_1365',
    {
      title: 'Ernesto',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/TEAL-1365'>
              TEAL-1365
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
  [
    'DC-7',
    {
      title: 'Hydro Point Water Meter Tools',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/DC-7'>
              DC-7
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
  [
    'DC-203',
    {
      title: 'Full EV - Test for multiple meters',
      description: (
        <StyledDescription>
          <p>
            {'See '}
            <StyledLink href='https://redaptiveinc.atlassian.net/browse/DC-203'>
              DC-203
            </StyledLink>
            {' for more information.'}
          </p>
        </StyledDescription>
      ),
    },
  ],
])
const variants = [...activeVariants].length ? activeVariants : defaultVariants
export default variants
