import Tippy from '@tippyjs/react'
import moment from 'moment-timezone'
import styled from 'styled-components'

import StatusIconItem from '../../../../../components/StatusIconItem'
import StyledLink from '../../../../../components/StyledLink'

const CellWithToolTip = styled.p<{ maxWidth?: number }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  max-width: ${(props) => props.maxWidth}px;
  width: 240px;
`
const convertToSiteTimezone = (date: string, siteTimezone: string) => {
  const convertedDate = moment(date).tz(siteTimezone)
  const formattedDate = convertedDate.format('MMMM DD, YYYY hh:mm:ss A z')
  return formattedDate
}
const CellComponent = ({
  original,
  value,
  column: { id, maxWidth },
}: Record<string, any>) => {
  const renderedValue = value ?? '-'
  switch (id) {
    case 'customerName':
    case 'siteName':
      return (
        <td data-testid={id}>
          <Tippy content={renderedValue} delay={500}>
            <CellWithToolTip maxWidth={maxWidth}>
              {renderedValue}
            </CellWithToolTip>
          </Tippy>
        </td>
      )
    case 'onlineStatus':
      return (
        <td data-testid={id}>
          {value === 'loading' ?
            <div>Loading...</div>
          : <StatusIconItem status={value} />}
        </td>
      )
    case 'verified':
      return <td data-testid={id}>{value ? 'Yes' : 'No'}</td>
    case 'firstReportDate':
    case 'mostRecentConfigurationDate':
      return (
        <td data-testid={id}>
          {(value && convertToSiteTimezone(value, original.siteTimezone)) ||
            '-'}
        </td>
      )
    case 'history':
      return (
        <td data-testid={id}>
          <StyledLink
            href={`/reports/electron-verify-dashboard-v1?meterMac=${renderedValue}`}
          >
            View
          </StyledLink>
        </td>
      )
    default:
      return <td data-testid={id}>{renderedValue}</td>
  }
}

export default CellComponent
