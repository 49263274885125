import Tippy from '@tippyjs/react'

import {
  alwaysLockedColumns,
  fieldMappings,
  handleColumnSettingsClose,
  initialHiddenColumns,
  initialLockedColumns,
} from './columnSettings'
import Button2 from '../../../../components/Button2'
import RedaptiveReactTable7 from '../../../../components/RedaptiveReactTable7'
import { useSiteReadiness } from '../../../../queries/hydropoint'
import { Cell } from '../../../MeterInstallGroupedSubmissions/styles'
import Loader from '../components/Loader'
import { paginationSize } from '../constants'
import { TableWrapperStyled, Title } from '../styles'

const SiteReadiness = () => {
  const { data, isFetching, refetch } = useSiteReadiness()

  const getColumnCell = (cellProps) => {
    const { value } = cellProps
    return (
      (
        <Tippy content={value}>
          <Cell maxWidth={200}>{value}</Cell>
        </Tippy>
      ) || '-'
    )
  }

  const columns = fieldMappings.map((item) => ({
    accessor: item.fieldName,
    Cell: getColumnCell,
    Header: item.label,
    minWidth: item.minWidth || 150,
    fieldName: item.fieldName,
  }))

  const handleCSVExport = () => {
    const headerRow = columns.map((column) => column.Header)
    const escapeCSVValue = (value) => {
      if (typeof value === 'string') {
        return `"${value.replace(/"/g, '""')}"`
      }
      return value
    }

    const csvArray =
      (data ?? []).map((item) =>
        columns.map((column) => item[column.accessor]).map(escapeCSVValue),
      ) ?? []

    csvArray.unshift(headerRow)
    const csvdata = csvArray.map((e) => e.join(',')).join('\n')
    const blob = new Blob([csvdata], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', 'Site_Readiness_Data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <>
      <div>
        <p>
          Use this tool to find calibrated meters that are ready to be assigned
          to a Grand Central site. This tool only returns un-assigned meters
          that meet the following readiness criteria:
        </p>
        <ul>
          <li>Must be Level 2 calibrated</li>
          <li>Must have a calibrated start date</li>
          <li>Must have a last calibration date</li>
        </ul>
        <p>
          If you’re looking for information about meters regardless of
          calibration level, use the Meter Details Tool below.
        </p>
      </div>

      <TableWrapperStyled>
        <Title>
          <div>Site Readiness</div>
        </Title>
        {!isFetching && (
          <>
            <Button2
              type='redaptiveTransparent'
              onClick={refetch}
              className='refresh-table'
            >
              Refresh Table
            </Button2>
            <Button2 type='redaptiveTransparent' onClick={handleCSVExport}>
              Export to CSV
            </Button2>
          </>
        )}
        <RedaptiveReactTable7
          columns={columns}
          data={data || []}
          globalFilterable={false}
          filterable
          disableSortRemove
          maxLockableColumns={3}
          enableColumnHiding
          enableColumnSettings
          initialHiddenColumns={initialHiddenColumns}
          initialLockedColumns={initialLockedColumns}
          alwaysLockedColumns={alwaysLockedColumns}
          handleColumnSettingsClose={handleColumnSettingsClose}
          initialPageSize={20}
          paginationSize={paginationSize}
          enablePagination
          enablePageSizeSelector
          hideDropdownPagination
          hideSeparator
          loading={isFetching}
          noDataFoundText='No data found. Change the filters and try again.'
        />
      </TableWrapperStyled>
      {isFetching && <Loader />}
    </>
  )
}

export default SiteReadiness
